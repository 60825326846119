<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF PENDING EMPLOYEES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md4>
          <v-select
            v-model="category_id"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Created Date
            </th>
            <th class="text-center text-uppercase">
              Name
            </th>
            <th class="text-center text-uppercase">
              Branch
            </th>
            <th class="text-center text-uppercase">
              Position
            </th>
            <th class="text-center text-uppercase">
              Date of Employment
            </th>
            <th class="text-center text-uppercase">
              Compensation Rate
            </th>
            <th class="text-center text-uppercase">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in employee_items"
            :key="item.id"
          >
            <td class="text-center">
              {{ date_format(item.created_at).format('MMMM DD, YYYY') }}
            </td>
            <td class="text-center">
              {{ item.last_name+','+item.first_name+' '+item.middle_name
              }}
            </td>
            <td class="text-center">
              {{ item.branch.branch_code }}
            </td>
            <td class="text-center">
              {{ item.employee_position.position }}
            </td>
            <td class="text-center">
              {{ date_format(item.date_of_employment).format('MMMM DD, YYYY') }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.compensation_rate) }}
            </td>
            <td class="text-center">
              <div v-if="item.is_approved===1">
                <v-chip color="success" dark>DONE</v-chip>
              </div>
              <div v-else-if="item.is_approved===2">
                <v-chip color="success" dark>DISAPPROVED</v-chip>
              </div>
              <div v-else-if="item.is_approved===0">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="approved(item)"
                >
                  {{icons.mdiCheck}}
                </v-icon>
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="actions(item,false)"
                >
                  {{icons.mdiCloseCircleOutline}}
                </v-icon>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="is_change_bank_details" persistent max-width="30%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CHANGE DETAILS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-text-field
          class="mt-3 mx-2"
          v-model="date_of_employment"
          label="Date of Eployment"
          dense
          outlined
          :rules="rules.default_max_45_character_and_no_empty_rule"
          type="date"
        ></v-text-field>
        <v-select
          v-if="false"
          class="mx-2 mt-3"
          v-model="category_id"
          dense
          label="Category"
          :items="category_items"
          item-value="id"
          item-text="category"
          :rules="rules.combobox_rule"
          @change="selected_category"
          outlined
        ></v-select>
        <v-select
          class="mx-2 mt-3"
          v-model="position_id"
          dense
          label="Position"
          :items="position_items"
          item-value="id"
          item-text="position"
          :rules="rules.combobox_rule"
          @change="selected_position"
          outlined
        ></v-select>
        <v-select
          class="mx-2 mt-3"
          v-model="branch_id"
          dense
          label="Branch"
          :items="branch_items"
          item-value="id"
          item-text="branch_code"
          :rules="rules.combobox_rule"
          outlined
        ></v-select>
        <v-checkbox v-model="is_allowance_only" label="Is Allowance" dense outlined></v-checkbox>
        <v-text-field
          class="mt-3 mx-2"
          v-model="emp_compensation"
          label="Compensation"
          dense
          outlined
          :rules="rules.default_max_45_character_and_no_empty_rule"
          type="number"
        ></v-text-field>
      </v-card>
      <v-btn color="primary" @click="actions(selected_employee,true)"> Save & Approved Changes
      </v-btn>
      <v-btn color="error" @click="is_change_bank_details = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiDelete, mdiCheck, mdiCloseCircleOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_allowance_only: false,
      category_id: '',
      emp_compensation: '',
      category_id2: '',
      branch_id: '',
      position_id: '',
      position_items: [],
      date_of_employment: '',
      category_items: [],
      employee_items: [],
      branch_items: [],
      category_items2: [],
      selected_employee: {},
      is_change_bank_details: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
          mdiCheck,
          mdiCloseCircleOutline,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['initialize_pending_employee', 'change_is_approved_employee']),
      ...mapActions('employee_change_position_branch_history', ['initialize_change_branch_position']),
      ...mapActions('branch_information', ['branch_data']),
      async initialize_data() {
        await this.initialize_pending_employee()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        var index = this.category_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.category_id)
        if (index != -1) {
          this.employee_items = this.category_items[index].employee
        }
      },
      selected_category2() {
        this.position_items = []
        var index = this.category_items2.map(function (x) {
          return x.id;
        }).indexOf(this.category_id2)
        if (index != -1) {
          this.position_items = this.category_items2[index].employee_position
        }
      },
      selected_position() {
        var index = this.position_items.map(function (x) {
          return x.id;
        }).indexOf(this.position_id)
        if (index != -1) {
          var position_selected = this.position_items[index].position
          this.branch_items = []
          this.branch_data({
            category_id: this.category_id2,
            is_region: position_selected.includes("REGION") ? 1 : 0,
            is_territory: position_selected.includes("TERRITORY") || position_selected.includes("VP") ? 1 : 0,
          })
            .then(response => {
              this.branch_items = response.data[0].active
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      async approved(item) {
        this.emp_compensation = item.compensation_rate + ''
        this.date_of_employment = item.date_of_employment
        this.selected_employee = item
        this.is_change_bank_details = true
        await this.initialize_change_branch_position()
          .then(response => {
            this.category_items2 = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.category_id2 = item.category_id
        await this.branch_data({
          category_id: this.category_id2,
          is_region: item.branch.is_region,
          is_territory: item.branch.is_territory,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
        this.is_allowance_only = item.is_allowance_only
        this.position_id = item.employee_position_id
        this.branch_id = item.branch_id
        await this.selected_category2()
      },
      async actions(item, status) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('is_approved', status);
        data.append('branch_id', this.branch_id);
        data.append('date_of_employment', this.date_of_employment);
        data.append('position_id', this.position_id);
        data.append('compensation_rate', this.emp_compensation);
        data.append('is_allowance_only', this.is_allowance_only ? 1 : 0);
        var cat_id = this.category_id
        await this.change_is_approved_employee(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULLY CHANGE STATUS',
            })
            Object.assign(this.$data, initialState())
          })
          .catch(error => {
            console.log(error)
          })
        await this.initialize_data()
        this.category_id = await cat_id
        await this.selected_category()
      },
    },
  }
</script>
